<template>
	<section
	class="coringCopyPopIn tw-z-[1]"
	@click="$event.stopPropagation()"
	>
		<v-card
		flat
		outlined
		class="coringCopyPopIn__card"
		>
			<v-card-title
			class="center justify-center"
			align-items
			>
				<h3>Suppression {{ title }}</h3>
			</v-card-title>

			<v-card-subtitle
			align-items
			class="tw-mt-[10px] tw-flex justify-center"
			>
				<p>
					Êtes-vous certain de vouloir supprimer {{ type === "campaign" ? "la campagne" : "le projet" }} ? 
					Cette action est irréversible et supprimera également tous les fichiers ou livrables liés {{ type === "campaign" ? "à la campagne" : "au projet" }}
				</p>
			</v-card-subtitle>

			<v-card-actions>
				<MediumButtonSlot @click="closePopin">
					Annuler
				</MediumButtonSlot>

				<v-spacer/>

				<ButtonSlot
				@click="deleteCampaignOrProject()"
				>
					Valider
				</ButtonSlot>
			</v-card-actions>
		</v-card>
	</section>
</template>

<script>
import {mapActions} from "vuex";
export default {
	name: "DeleteCampaignProjectModal",
	props: {
		type: {
			default: false,
		},
		campaignOrProjectId: {
			default: false
		}
	},
	data(){
		return {
			title: ""
		};
	},
	methods: {
		...mapActions("workspace", ["updateWorkspaces"]),
		...mapActions("project", ["updateCampaigns"]),

		closePopin(){
			this.$emit("closeModale");
		},

		async deleteCampaignOrProject(){
			if(this.type === "campaign"){
				this.$api.campaigns.deleteCampaign(this.campaignOrProjectId).then(() => {
					this.closePopin();
				});
			}
			else {
				this.$api.workspaces.deleteWorkspace(this.campaignOrProjectId).then(() => {
					this.closePopin();
				});
			}
		},
	},
	mounted(){
		this.$root.$on("closeModale", this.closePopin);
		this.title = this.type === "campaign" ? "de la campagne" : "du projet";
	}
};

</script>

<style lang="scss" scoped>

@import "@/assets/scss/_extends.scss";

.coringCopyPopIn {
  @extend %popinView;

  .table {
    th {
      text-align: center !important;
    }
  }

  .v-card {
    width: 40%;
    text-align: center;
    padding: 20px 20px;
    max-height: 90%;
    overflow: auto;
  }

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .v-input--selection-controls {
	margin-top: 0px !important;
  }

  
}

</style>
