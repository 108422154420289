<template>
	<div class="CreatePopin">
		<v-card
		flat
		outlined
		>
			<h3>
				{{ false === isSiteEdit ? "Ajouter un site" : "Modifier un site" }}
			</h3>

			<v-form
			class="tw-grid tw-grid-cols-12 tw-w-full tw-gap-x-[10px] tw-overflow-hidden tw-transition-all tw-pt-[20px]"
			ref="form"
			v-model="isFormValid"
			@submit.prevent="confirmModifications"
			>
				<v-text-field
				dense
				autofocus
				tabindex="1"
				outlined
				label="Nom *"
				:rules="[$rules.required]"
				clearable
				v-model="site.name"
				ref="name"
				@focus="$refs.name.resetValidation()"
				@blur="
					site.name === '' || site.name === null
						? $refs.name.resetValidation()
						: ''
				"
				:validate-on-blur="!isSiteEdit"
				class="tw-col-span-6"
				/>

				<v-autocomplete
				v-if="false === organization.isInternal"
				:disabled="!$hasRight('sites.editCommercial')"
				dense
				tabindex="2"
				outlined
				label="Commercial"
				:items="commercials"
				clearable
				item-value="id"
				:item-text="item => item.firstname + ' ' + item.lastname"
				v-model="site.commercialId"
				:validate-on-blur="!isSiteEdit"
				class="tw-col-span-6"
				/>
					
				<v-autocomplete
				v-if="organization.id === '6e42295a-a6e4-43b8-84d9-d37c5d9db7b3' &&
					true === organization.isInternal"
				dense
				tabindex="2"
				outlined
				label="Agence *"
				:rules="[$rules.required]"
				:items="agencies"
				clearable
				item-value="id"
				item-text="name"
				v-model="site.agencyId"
				:validate-on-blur="!isSiteEdit"
				class="tw-col-span-6"
				/>

				<v-text-field
				dense
				label="Email"
				type="email"
				tabindex="3"
				clearable
				outlined
				v-model="site.email"
				:rules="[$rules.validEmail]"
				class="tw-col-span-6"
				/>

				<v-text-field
				dense
				tabindex="4"
				outlined
				label="Téléphone"
				v-model="site.phone"
				clearable
				:rules="[$rules.validPhoneNumber]"
				ref="phone"
				@focus="$refs.phone.resetValidation()"
				@blur="
					site.phone === '' || site.phone === null
						? $refs.phone.resetValidation()
						: ''
				"
				:validate-on-blur="!isSiteEdit"
				class="tw-col-span-6"
				/>

				<v-text-field
				v-if="true === organization.isInternal"
				dense
				tabindex="5"
				outlined
				label="Nom raccourci *"
				clearable
				v-model="site.shortName"
				:rules="[$rules.required]"
				ref="shortName"
				@focus="$refs.shortName.resetValidation()"
				@blur="
					site.shortName === '' || site.shortName === null
						? $refs.shortName.resetValidation()
						: ''
				"
				:validate-on-blur="!isSiteEdit"
				class="tw-col-span-6"
				/>

				<v-text-field
				v-if="true === organization.isInternal"
				dense
				tabindex="6"
				outlined
				label="Numéro de SIRET *"
				clearable
				v-model="site.siret"
				:rules="[$rules.validSiret]"
				ref="shortName"
				:validate-on-blur="!isSiteEdit"
				class="tw-col-span-6"
				/>

				<editable-list-field
				v-if="true === organization.isInternal"
				dense
				v-model="siteProviders"
				label="Prestataire(s)"
				:item-text="item => item.provider.name"
				:empty-item="siteProvider"
				modale-title="Paramétrer le prestataire"
				class="tw-col-span-6"
				>
					<template v-slot:modale="siteProvider">
						<v-form ref="providerForm">
							<v-autocomplete
							label="Prestataire"
							:items="providers"
							item-text="name"
							:item-value="item => item"
							v-model="siteProvider.newItem.provider"
							clearable
							tabindex="100"
							autofocus
							outlined
							:rules="[$rules.required]"
							:validate-on-blur="!isSiteEdit"
							/>

							<v-checkbox
							v-model="authProviderRequired"
							label="Authentification requise"
							/>

							<div v-if="authProviderRequired">
								<v-text-field
								label="Nom utilisateur"
								v-model="siteProvider.newItem.username"
								clearable
								outlined
								:rules="[$rules.required]"
								tabindex="101"
								ref="username"
								@focus="$refs.username.resetValidation()"
								@blur="
									siteProvider.newItem.username === '' ||
										siteProvider.newItem.username === null
										? $refs.username.resetValidation()
										: ''
								"
								:validate-on-blur="!isSiteEdit"
								/>

								<v-text-field
								label="Mot de passe"
								v-model="siteProvider.newItem.password"
								clearable
								:rules="[$rules.required]"
								outlined
								tabindex="102"
								ref="password"
								@focus="$refs.password.resetValidation()"
								@blur="
									siteProvider.newItem.password === '' ||
										siteProvider.newItem.password === null
										? $refs.password.resetValidation()
										: ''
								"
								:validate-on-blur="!isSiteEdit"
								/>
							</div>
						</v-form>
					</template>
				</editable-list-field>

				<div class="tw-col-span-12 tw-flex">
					<div class="tw-flex container-button tw-w-full">
						<ButtonSlot
						_theme="light-gray"
						@click="closePopin"
						>
							Annuler
						</ButtonSlot>

						<ButtonSlot
						_type="submit"
						:_disabled="!isFormValid"
						>
							Valider
						</ButtonSlot>
					</div>
				</div>
			</v-form>
		</v-card>
	</div>
</template>

<script>
export default {
	props: [
		"organization", "isSiteEdit", "siteToEdit"
	],
	data(){
		return {
			isFormValid: false,
			site: {
				name: "",
				organizationId: null,
				commercialId: null,
				agencyId: null,
				street: "",
				detail: "",
				zipCode: "",
				city: "",
				country: "",
				email: "",
				mobile: "",
				phone: "",
				shortName: null,
				siret: null
			},
			siteProvider: {
				id: null,
				username: null,
				password: null,
				site: {
					id: null,
					name: null,
					phone: null,
					mobile: null
				},
				provider: {
					id: null,
					name: null
				}
			},
			authProviderRequired: true,
			providers: [],
			siteProviders: [],
			rules: {
				oneFilled: () => {
					return (
						this.site.phone.length === 10 ||
            this.site.mobile.length === 10 ||
            "au moins un numéro de téléphone requis"
					);
				}
			},
			commercials: [],
			agencies: []
		};
	},
	methods: {
		async getProviders(){
			this.providers = await this.$api.providers.findAll();
			this.providers.sort(function(a, b){
				return a.name.localeCompare(b.name);
			});
		},
		async getSiteProviders(){
			this.siteProviders = await this.$api.sites.getSiteProviders(
				this.siteToEdit.id
			);
			this.previousProviders = JSON.parse(JSON.stringify(this.siteProviders));
		},
		refreshModale(){
			this.$emit("refreshModale");
		},

		async getCommercials(){
			this.commercials = await this.$api.users.findByJobAndOrganization(
				"commercial",
				this.organization.parentOrganization.id
			);
			this.commercials.sort((a, b) => a.firstname.localeCompare(b.firstname));
		},
		async getAgencies(){
			this.agencies = await this.$api.agencies.findAll();
		},
		closePopin(){
			this.$emit("closePopin");
		},
		async confirmModifications(){

			if(false === this.isSiteEdit){
				const site = await this.$api.sites.create(this.site);

				this.siteProviders.forEach(async ap => {
					await this.$api.sites.addProviderToSite(site.id, ap.provider.id, ap);
				});

				this.$emit("siteCreated", site);
			}
			else if(true === this.isSiteEdit){
				await this.$api.sites.edit(this.site.id, this.site);

				this.siteProviders.forEach(ap => {
					if(null === ap.id) this.$api.sites.addProviderToSite(this.site.id, ap.provider.id, ap);
					else this.$api.sites.editSiteProvider(this.site.id, ap.provider.id, ap);
				});

				this.previousProviders.forEach(ap => {
					const index = this.siteProviders.findIndex(ap2 => ap2.id === ap.id);

					if(-1 === index) this.$api.sites.removeProviderFromSite(ap.site.id, ap.provider.id);
				});
			}

			this.$emit("siteEdited");
			this.closePopin();
		}
	},
	mounted(){
		this.site.organizationId = this.organization.id;
		this.getProviders();
		if(this.organization.isInternal === false);
		this.getCommercials();
		this.getAgencies();

		if(null !== this.siteToEdit){
			this.site = {
				id: this.siteToEdit.id,
				name: this.siteToEdit.name,
				organizationId: this.$route.params.id,
				commercialId: this.siteToEdit.commercial?.id,
				agencyId: this.siteToEdit.agency?.id,
				street: this.siteToEdit.address.street,
				detail: this.siteToEdit.address.detail,
				zipCode: this.siteToEdit.address.zipcode,
				city: this.siteToEdit.address.city,
				country: this.siteToEdit.address.country,
				email: this.siteToEdit.email,
				mobile: this.siteToEdit.contact.mobile,
				phone: this.siteToEdit.contact.phone,
				shortName: this.siteToEdit.shortName,
				siret: this.siteToEdit.siret
			};
			this.getSiteProviders();
			this.$refs.form.validate();
		}

		this.$root.$on("closeCurrent", () => this.$emit("closePopin"));
	}
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_extends.scss";

.CreatePopin {
  @extend %popinView;

  &__card p {
    text-align: left !important;
    margin: 0 0 20px 0 !important;
  }

  .container-button{
        display: flex;
        justify-content: space-between;
    }
}

.row {
  margin-top: 0 !important;
}
</style>
